<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'Question.Create' }">{{
        $vuetify.lang.t("$vuetify.newQuestion")
      }}</v-btn>
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.question") }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-dialog
          ref="dialog"
          v-model="dateFilterModal"
          :return-value.sync="options.dateFilter"
          width="290px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Search by Date"
              prepend-icon="mdi-calendar"
              :append-icon="options.dateFilter[0] !== '' ? 'mdi-close' : ''"
              @click:append="options.dateFilter = ['', '']"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="options.dateFilter" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateFilterModal = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(options.dateFilter)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <data-table
      v-model="selectedQuestions"
      :headers="headers"
      :items="questions"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      item-key="id"
      show-select
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
        <v-btn
          v-if="selectedQuestions.length !== 0"
          class="ml-2"
          color="primary"
          @click="copyQuestions"
          >{{ $vuetify.lang.t("$vuetify.copyQuestions") }}</v-btn
        >
      </template>
      <template
        v-slot:[`item.questionPackName`]="{ item: { questionPackName } }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                searchForm = questionPackName;
                options.search = questionPackName;
                options.page = 1;
              "
              class="question-pack"
              >{{ questionPackName }}</span
            >
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.filterQuestionPack") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.question`]="{ item: { question } }">
        <span>{{ question | stripHtml | ellipsis }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item: { createdAt } }">
        <span>{{ createdAt | parseStringDate }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t("$vuetify.option") }}
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="copyQuestion(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.copyQuestion")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Question.Edit', params: { id } }" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.edit")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deletePrompt(id)" link>
              <v-list-item-title>{{
                $vuetify.lang.t("$vuetify.delete")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </data-table>

    <modal
      v-model="dialog"
      :loading="loadingDelete"
      :handler="deleteData"
      :title="$vuetify.lang.t('$vuetify.modal.deleteData.title')"
      :description="$vuetify.lang.t('$vuetify.modal.deleteData.description')"
      :text-button="$vuetify.lang.t('$vuetify.modal.deleteData.textButton')"
      :negative="true"
    />
  </v-container>
</template>

<style scoped>
.question-pack:hover {
  cursor: pointer;
}
</style>

<script>
import format from "date-fns/format";
import { mapActions } from "vuex";
import Question from "@/services/question";
import DataTable from "@/components/DataTable.js";
import Modal from "@/components/Modal";

export default {
  name: "Question.Index",
  components: {
    DataTable,
    Modal
  },
  data() {
    return {
      totalData: 0,
      questions: [],
      loading: true,
      loadingDelete: false,
      options: {
        dateFilter: ["", ""],
        search: undefined
      },
      headers: [
        { text: "Position", value: "position", width: "100px" },
        { text: "Question Pack", value: "questionPackName", width: "130px" },
        { text: "Question", value: "question" },
        { text: "Created At", value: "createdAt", width: "145px" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: "",
      selectedQuestions: [],
      dateFilterModal: false,
      searchForm: null
    };
  },
  computed: {
    selectedIds() {
      return this.selectedQuestions.map(({ id }) => id);
    },
    dateRangeText() {
      return this.options.dateFilter.join(" ~ ");
    }
  },
  watch: {
    options: {
      handler() {
        this.getQuestions();
      },
      deep: true
    }
  },
  filters: {
    ellipsis(questionText) {
      return questionText.length > 100
        ? `${questionText.substring(0, 100)}...`
        : questionText;
    },
    stripHtml(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      const text = div.textContent || div.innerText || "";
      return text;
    },
    parseStringDate(date) {
      return format(new Date(date), "yyyy-MM-dd HH:mm");
    }
  },
  created() {
    this.setNavbarTitle("Manage Question");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getQuestions() {
      this.loading = true;
      this.questions = [];
      const questions = await Question.index(this.options);
      this.questions = questions.data.data.questions;
      this.totalData = questions.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Question.delete(this.selectedId);
        await this.getQuestions();
        this.dialog = false;
      } catch (error) {
        this.loadingDelete = false;
      }
    },
    async copyQuestion(id) {
      await Question.copy([id]);
      await this.getQuestions();
    },
    async copyQuestions() {
      if (this.selectedIds.length > 10) {
        return this.$snackbar.showMessage({
          content: "Selected question cannot be more than 10!",
          variant: "error"
        });
      }
      this.$snackbar.showMessage({
        content:
          "The process is run in the background. You may need to refresh the page to see the copied questions",
        variant: "info"
      });
      await Question.copy(this.selectedIds);
      await this.getQuestions();
      this.selectedQuestions = [];
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>
